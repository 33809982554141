import React from "react"
import RawLayout from "./raw-layout"
import Main from "../main"

const NoSideBarLayout: React.FC = ({ children }) => {
  return (
    <RawLayout>
      <Main>{children}</Main>
    </RawLayout>
  )
}

export default NoSideBarLayout
