import React from "react"
import ReactGA from "react-ga"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import Column from "./column"
import Row from "./row"

const Footer = () => {
  return (
    <div id="footer-wrapper">
      <footer id="footer" className="container">
        <Row>
          <Column large={3} medium={6} small={12}>
            <section className="widget links">
              <h3>Policies</h3>
              <ul className="style2">
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/data-retention-policy">Data Retention Policy</Link>
                </li>
                <li>
                  <Link to="/branding-guidelines">Brand Guidelines</Link>
                </li>
              </ul>
            </section>
          </Column>
          <Column large={3} medium={6} small={12}>
            <section className="widget links">
              <h3>About Hackathons UK</h3>
              <ul className="style2">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/join">Join</Link>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_caf_main"
                    to="https://hackuk.org/donate"
                    rel="noopener noreferrer"
                  >
                    Donate
                  </ReactGA.OutboundLink>
                </li>
              </ul>
            </section>
          </Column>
          <Column large={3} medium={6} small={12}>
            <section className="widget links">
              <h3>Projects and Partners</h3>
              <ul className="style2">
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_projects_and_partners"
                    to="https://hackuk.org/newsletter"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Newsletter
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_projects_and_partners"
                    to="https://hackquarantine.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Hack Quarantine
                  </ReactGA.OutboundLink>
                </li>
              </ul>
            </section>
          </Column>
          <Column large={3} medium={6} small={12}>
            <section className="widget contact last">
              <h3>Contact Us</h3>
              <ul className="style2">
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_socials"
                    to="https://twitter.com/hackathons_uk"
                    className="icon"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_socials"
                    to="http://facebook.com/hackathons.org.uk"
                    className="icon"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_socials"
                    to="https://instagram.com/hackathons.uk"
                    className="icon"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_socials"
                    to="https://github.com/hackathons-uk"
                    className="icon"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="footer_link_socials"
                    to="mailto:hello@hackathons.org.uk"
                    className="icon"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </ReactGA.OutboundLink>
                </li>
              </ul>
              <p>
                <Link to="/contact">Contact Form</Link>
              </p>
            </section>
          </Column>
        </Row>
        <br />
        <Row>
          <Column>
            <div id="copyright">
              <ul className="menu">
                <li>
                  &copy; 2020. Hackathons UK Limited. All Rights Reserved.
                </li>
                <li>
                  Charity Registered in England No. 1195243 and Company Limited
                  by Guarantee Registered in England No. 12530692
                </li>
              </ul>
            </div>
          </Column>
        </Row>
      </footer>
    </div>
  )
}

export default Footer
