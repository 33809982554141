import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navigation from "./navigation"
// import logo from "../assets/images/logo.svg"
import Logo from './logo';

const Header = ({ siteTitle }) => (
  <div id="header-wrapper">
    <header id="header" className="container">
      <div id="logo">
        <Link to="/">
          {/* <img src={logo} style={{ width: "100%" }} alt={siteTitle} /> */}
          <Logo alt={siteTitle} style={{ width: "100%", maxWidth: "300px" }} />
        </Link>
      </div>
      <Navigation />
    </header>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
