import React from "react"
import NavItemType from "../types/NavItem"
import NavItem from "./nav-item"

const navItems: NavItemType[] = [
  {
    to: "/",
    content: "Home",
  },
  {
    to: "/events",
    content: "Events",
    children: [
      {
        to: "/events",
        content: "Upcoming Events"
      },
      {
        to: "/events/past",
        content: "Past Events"
      },
    ]
  },
  {
    to: "/contact",
    content: "Get in Touch",
    children: [
      {
        to: "https://hackuk.org/partner-event-apply",
        content: "Event Partnership",
      },
      {
        to: "/sponsor-interest",
        content: "Work With Us",
      },
      {
        to: "/contact",
        content: "General inquires",
      },
    ],
  },
  {
    to: "https://hackuk.org/discord",
    content: "Join the Community",
    // children: [
    //   {
    //     to: "https://hackuk.org/discord",
    //     content: "Join the Hackathons UK Discord"
    //   },
    //   {
    //     to: "/socieites",
    //     content: "Find Your Local Society"
    //   },      
    //   {
    //     to: "/join/member",
    //     content: "Become a Member"
    //   }
    // ]
  },
]

const Navigation = () => {
  return (
    <nav id="nav">
      <ul>
        {navItems.map((item, i) => (
          <NavItem item={item} key={i} />
        ))}
      </ul>
    </nav>
  )
}

export default Navigation
