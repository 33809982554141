import React, { useState, useMemo } from "react"
import NavItemType from "../types/NavItem"
import { Link } from "gatsby"
import cx from "classnames"

interface NavItemProps {
  item: NavItemType
}

const NavItem: React.FC<NavItemProps> = ({ item }) => {
  const [isActive, setActive] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false);

  const isExternal = useMemo(() => item.to.substr(0, 4) === 'http', [item.to]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const getPropsHandler = ({ isCurrent }: { isCurrent: boolean }) => {
    setActive(isCurrent)
    return {}
  }

  return (
    <li
      className={cx({
        opener: !!item.children,
        current: isActive,
        dropdownParentHovered: menuOpen,
      })}
      onMouseEnter={toggleMenu} onMouseLeave={toggleMenu}
    >
      {isExternal ? (<a href={item.to} target="_blank" rel="noopener noreferrer">{item.content}</a>) : (
      <Link to={item.to || "#"} getProps={getPropsHandler}>
        {item.content}
      </Link>)}
      {item.children && menuOpen && (
        <ul className="dropotron">
          {item.children.map((child, i) => (
            <NavItem item={child} key={i} />
          ))}
        </ul>
      )}
    </li>
  )
}

export default NavItem
