import React from "react"
import Container from "./container"

const Main: React.FC = ({ children }) => {
  return (
    <div id="main-wrapper">
      <Container>{children}</Container>
    </div>
  )
}

export default Main
